
<template>
  <div class="main-content">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

    <div class="card text-left">
      <div class="card-body">
        <b-tabs
          active-nav-item-class="nav nav-tabs"
          content-class="mt-3"
        >
          <b-tab title="Shop Categories" active>

            <div class="row">
               <div class="col-md-12">
                  <b-button class="btn btn-danger float-right" v-b-modal.add-category>Add new</b-button>
               </div>
            </div>

            <br/>

            <b-table striped hover :items="categories" :fields="columns">
              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" @click="openEditCategoryModal(data.item)">Edit</b-dropdown-item>
                  <b-dropdown-item href="#" @click="openDeleteCategoryModal(data.item)">Delete</b-dropdown-item>
                </b-dropdown>

              </template>
            </b-table>
          </b-tab>
          <b-tab title="Promotions">
            <div class="row">
              <div class="col-md-12">
                <b-button class="btn btn-danger float-right" v-b-modal.add-promotion>Add new</b-button>
              </div>
            </div>

            <br/>

            <b-table striped hover :items="promotions" :fields="columns_promotions">

              <template v-slot:cell(promotionalUrl)="data">
                <img style="width: 100px; height: 70px;" :src="data.value" class="img img-responsive img-thumbnail" />
              </template>

              <template v-slot:cell(outlet)="data">
                <span v-if="data.value != 'null'">{{ data.value.name }}</span>
              </template>

              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                 <!-- <b-dropdown-item href="#" @click="openEditNoticeBoardModal(data.item)">Edit</b-dropdown-item>-->
                  <b-dropdown-item href="#" @click="openDeletePromotion(data.item)">Delete</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Noticeboard"  >

            <div class="row">
              <div class="col-md-12">
                <b-button class="btn btn-danger float-right" v-b-modal.add-notice>Add new</b-button>
              </div>
            </div>

            <br/>

            <b-table striped hover :items="notices" :fields="columns_noticeboard">

              <template v-slot:cell(imgUrl)="data">
                  <img style="width: 100px; height: 70px;" :src="data.value" class="img img-responsive img-thumbnail" />
              </template>

              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" @click="openEditNoticeBoardModal(data.item)">Edit</b-dropdown-item>
                  <b-dropdown-item href="#" @click="openDeleteNotice(data.item)">Delete</b-dropdown-item>
                </b-dropdown>

              </template>
            </b-table>

          </b-tab>
          <b-tab title="Third party apps" >
            <div class="row">
              <div class="col-md-12">
                <b-button class="btn btn-danger float-right" v-b-modal.add-user>Add new</b-button>
              </div>
            </div>

            <b-table striped hover :items="users" :fields="columns_app_management">
              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" @click="openEditCategoryModal(data.item)">Edit</b-dropdown-item>
                  <b-dropdown-item href="#" @click="openDeleteUser(data.item)">Delete</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Loyalty Settings">
            <form style="padding: 20px;" method="post" v-on:submit.prevent="saveLoyaltySettings">

              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label >
                      <strong>1 Loyalty point:</strong>
                    </label>
                    <div>
                      <input class="form-control" readonly v-model="loyalty_settings.loyalty_cash_value" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">

                </div>
              </div>

              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label >
                      <strong> Maximum points  per Shop Attendant / Day:</strong>
                    </label>
                    <div>
                      <input class="form-control" readonly v-model="loyalty_settings.max_loyalty_points_sa" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">

                </div>
              </div>


              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label >
                      <strong> Maximum points per Customer / Day:</strong>
                    </label>
                    <div>
                      <input class="form-control" readonly v-model="loyalty_settings.max_loyalty_points_customer" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">

                </div>
              </div>

              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label >
                      <strong> Netsuite Environment:</strong>
                    </label>
                    <div>
                      <input class="form-control" v-model="loyalty_settings.netsuiteEnv" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">

                </div>
              </div>

              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label >
                      <strong> Netsuite Item Id:</strong>
                    </label>
                    <div>
                      <input class="form-control" v-model="loyalty_settings.netsuiteItemId" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">

                </div>
              </div>

              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-6">
                  <button type="submit" class="btn btn-success float-right"> Save </button>
                </div>
                <div class="col-md-3">

                </div>
              </div>
            </form>
          </b-tab>
          <b-tab title="Tier Settings">
            <form style="padding: 20px;" method="post" v-on:submit.prevent="saveTierSettingsData">

              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label >
                      <strong>Premium Minimum Purchase:</strong>
                    </label>
                    <div>
                      <input class="form-control" v-model="tierSettings.premiumMinimumPurchase" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">

                </div>
              </div>

              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label >
                      <strong> Remind customer in (x) days prior :</strong>
                    </label>
                    <div>
                      <input class="form-control" v-model="tierSettings.reminderDays" type="text" />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">

                </div>
              </div>


              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label >
                      <strong> Reminder SMS:</strong>
                    </label>
                    <div>
                      <textarea cols="7" style="width: 100%;" class="form-control" v-model="tierSettings.reminderSMS"></textarea>
                    </div>
                  </div>
                </div>
                <div class="col-md-3">

                </div>
              </div>

              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-6">
                  <button type="submit" class="btn btn-success float-right"> Save </button>
                </div>
                <div class="col-md-3">

                </div>
              </div>
            </form>
          </b-tab>
          <b-tab title="Lottery Settings" >
            <form action="#" class="form-horizontal" v-on:submit.prevent="saveLotterySettings"  method="post" >


              <div class="form-body">
                <div class="row">
                  <div class="col-md-2">
                  </div>
                  <div class="col-md-4">
                    <div class="form-group ">
                      <label class="control-label ">Number of tickets:</label> <br/>
                      <div >
                        <input v-model="lottery_settings.num_of_tickets"  required type="text" class="form-control" placeholder="" name="num_tickets" id="num_tickets"  />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group ">
                      <label class="control-label">Amount (KES equivalent):</label> <br/>
                      <div >
                        <input v-model="lottery_settings.conversion_rate" required  type="text" class="form-control" placeholder="" name="conversion_rate" id="conversion_rate"  />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">

                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12">
                    <hr/>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label class="control-label col-sm-4">Default number of tickets per customer:</label> <br/>
                      <div class="col-sm-8">
                        <input v-model="lottery_settings.num_tickets_per_customer"  required type="text" class="form-control" placeholder="" name="num_tickets" id="num_tickets_per_customer"  />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">

                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <hr/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label class="control-label col-sm-4">Number of transactions per day:</label> <br/>
                      <div class="col-sm-8">
                        <input v-model="lottery_settings.num_of_transactions_per_day"  required type="text" class="form-control" placeholder="" name="num_of_transactions_per_day" id="num_of_transactions_per_day"  />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">

                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <hr/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-2"></div>
                  <div class="col-md-8">
                    <div class="form-group row">
                      <label class="control-label col-sm-4">Maximum amount per day:</label> <br/>
                      <div class="col-sm-8">
                        <input v-model="lottery_settings.maximum_amount_per_day"  required type="text" class="form-control" placeholder="" name="maximum_amount_per_day" id="maximum_amount_per_day"  />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2">

                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <hr/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <button type="submit" class="btn btn-primary btn-lg float-right">Save</button>
                  </div>
                </div>
              </div>

            </form>
          </b-tab>
          <b-tab title="General Settings">
            <form style="padding: 20px;" method="post" v-on:submit.prevent="saveGeneralSettingsData">

              <div class="row">
                  <div class="col-md-3">

                  </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label><strong>Opening time:</strong> </label> <br/>
                    <date-picker  format="HH:mm" value-type="format" v-model="general_settings.openingTime" type="time" placeholder="Select time"></date-picker>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                        <hr/>
                    </div>
                  </div>
                  <div class="form-group">
                    <label><strong>Closing time: </strong></label> <br/>
                    <date-picker  format="HH:mm" value-type="format" v-model="general_settings.closingTime" type="time" placeholder="Select time"></date-picker>
                  </div>

                  <div class="row">
                    <div class="col-md-12">
                      <hr/>
                    </div>
                  </div>

                  <div class="form-group">
                    <label><strong>Notification email for ID uploads: </strong></label> <br/>
                    <input class="form-control" type="text" v-model="general_settings.customerServiceEmail" />
                  </div>

                  <button type="submit" class="btn btn-success float-right"> Save </button>
                </div>
                <div class="col-md-3">

                </div>
              </div>

            </form>
          </b-tab>
          <b-tab title="SMTP Settings">
            <form style="padding: 20px;" method="post" v-on:submit.prevent="saveSMTPSettingsData">
              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-6">
                  <div class="form-group" >
                    <br/>
                    <strong>Email provider: </strong> <br/>
                    <label for="outlook">
                      <input type="radio" name="email_provider" id="outlook" value="OUTLOOK" v-model="smtp_settings.provider" > &nbsp; Outlook
                    </label> <br/>
                    <label for="gmail">
                      <input type="radio" name="email_provider" id="gmail" value="GMAIL" v-model="smtp_settings.provider" > &nbsp; Gmail
                    </label>
                    <label for="postmark">
                      <input type="radio" name="email_provider" id="postmark" value="POSTMARK" v-model="smtp_settings.provider" > &nbsp; Postmark
                    </label>
                  </div>
                </div>
                <div class="col-md-3">

                </div>
              </div>
              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-3" v-if="smtp_settings.provider != 'POSTMARK'">
                  <div class="form-group">
                    <label >
                      <strong>Host:</strong>
                    </label>
                    <div>
                      <input type="text" class="form-control" v-model="smtp_settings.host"  />
                    </div>
                  </div>
                </div>
                <div class="col-md-3" v-if="smtp_settings.provider != 'POSTMARK'">
                  <div class="form-group">
                    <label >
                      <strong>Port:</strong>
                    </label>
                    <div>
                      <input style="width: 100%;" type="number" class="form-control" v-model="smtp_settings.port"  />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">

                </div>
              </div>



              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label >
                      <strong>Email:</strong>
                    </label>
                    <div>
                      <input type="email" class="form-control" v-model="smtp_settings.email"  />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label >
                      <strong>Password:</strong>
                    </label>
                    <div>
                      <input type="password" class="form-control" v-model="smtp_settings.password"  />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">

                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <hr/>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">

                </div>
                <div class="col-md-6">
                  <button type="submit" class="btn btn-success float-right"> Save </button>
                </div>
                <div class="col-md-3">

                </div>
              </div>


            </form>
          </b-tab>
          <b-tab title="Roles and Permissions">

            <div class="row">
               <div class="col-md-12">
                 <a href="/apps/createPermissions" class="btn btn-danger float-right"><i class="fa fa-plus"></i> Add new</a>
               </div>
            </div>
            <b-table striped hover :items="roles" :fields="headers_roles">

              <template v-slot:cell(imgUrl)="data">
                <img style="width: 100px; height: 70px;" :src="data.value" class="img img-responsive img-thumbnail" />
              </template>

              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" @click="navigateEditRole(data.item.id)">Edit</b-dropdown-item>
                </b-dropdown>

              </template>
            </b-table>
          </b-tab>
          <b-tab title="Outlet points conversion ">
            <div class="row">
              <div class="col-md-12">
                <a href="/apps/createConversion" class="btn btn-danger float-right"><i class="fa fa-plus"></i> Add new</a>
              </div>
            </div>
            <b-table striped hover :items="loyaltyConverions" :fields="header_merchant_conversions">

              <template v-slot:cell(outlet)="data">
                {{ data.item.outlet.name}}
              </template>

              <template v-slot:cell(accrual)="data">
                KES. {{ data.item.accrual_value }} = {{ data.item.loyalty_points_accrual }}
              </template>

              <template v-slot:cell(redemption)="data">
                KES. {{ data.item.redemption_value }} = {{ data.item.loyalty_points_redemption }}
              </template>

              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" @click="navigateEditConversion(data.item.id)">Edit</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Taxes ">
            <div class="row">
              <div class="col-md-12">
                <b-button type="button" v-b-modal.add-tax class="btn btn-danger float-right"><i class="fa fa-plus"></i> Add new</b-button>
              </div>
            </div>
            <b-table striped hover :items="taxes" :fields="columns_taxes">

              <template v-slot:cell(taxValue)="data">
                {{ data.item.taxValue }} %
              </template>


              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="Action">
                  <b-dropdown-item href="#" @click="openDeleteTax(data.item)">Delete</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Partners">
            <div class="row">
              <div class="col-md-3">
                <b-input-group append="Find" class="mb-2 mr-sm-2 mb-sm-0">
                  <b-input id="inline-form-input-username" placeholder="Search partner"></b-input>
                </b-input-group>
              </div>
              <div class="col-md-9">
                <b-button class="btn btn-danger float-right" v-b-modal.add-partner>Add new</b-button>
              </div>
            </div>
            <br/>
            <b-table striped hover :items="partners" :fields="partner_columns">

              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="options">
                  <b-dropdown-item @click="openEditPartnerModal(data.item)"  >Edit</b-dropdown-item>
                  <b-dropdown-item @click="openDeletePartner(data.item)"  >Delete</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-tab>
          <b-tab title="Report Recipients">
            <div class="row">
              <div class="col-md-12">
                <b-button class="btn btn-danger float-right" v-b-modal.add-report-recipient>Add new</b-button>
                <b-button class="btn btn-success float-right ml-3 mr-3" @click="openTriggerMonthlyReports">Send Reports</b-button>
              </div>
            </div>
            <br/>
            <b-table striped hover :items="reportRecipientSettings" :fields="columns_report_recipient_settings">

              <template v-slot:cell(options)="data">
                <!-- `data.value` is the value after formatted by the Formatter -->
                <b-dropdown text="options">
                  <b-dropdown-item @click="openEditReportRecipientSettingsModal(data.item)"  >Edit</b-dropdown-item>
                  <b-dropdown-item @click="openDeleteReportRecipient(data.item)"  >Delete</b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <b-modal v-b-modal.modal-lg id="add-notice" title="Add item to noticeboard" @cancel="true" @ok="validateBeforeSubmitNewNotice"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Title:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="noticeboard.title" />
                </div>
              </div>
            </div>
          </div>

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Category:</label> <br/>
                <div >
                  <select class="form-control" v-model="noticeboard.category">
                      <option v-for="category in noticeboard_categories"> {{ category }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="control-label">Validity:</label>
              <date-picker  :lang="lang" v-model="noticeboard.startDate" valueType="format" :first-day-of-week="1"></date-picker> to
              <date-picker  :lang="lang" v-model="noticeboard.endDate" valueType="format" :first-day-of-week="1"></date-picker>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="control-label">Poster:</label> <br/>
              <input class="form-control" type="file"  ref="poster" @change="handlePosterUpload" />
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal v-b-modal.modal-lg id="add-promotion" title="Add promotion" @cancel="true" @ok="validateBeforeSubmitNewPromotion"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Outlet:</label> <br/>
                <div >
                  <select class="form-control" v-model="promotion.outletId">
                      <option value="0"> -- None -- </option>
                      <option :value="outlet.id" v-for="outlet in outlets">{{ outlet.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="promotion.name" />
                </div>
              </div>
            </div>
          </div>

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Description:</label> <br/>
                <div >
                  <textarea class="form-control" v-model="promotion.description" style="width: 100%" rows="7"></textarea>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="control-label">Validity:</label>
              <date-picker  :lang="lang" v-model="promotion.startDate" valueType="format" :first-day-of-week="1"></date-picker> to
              <date-picker  :lang="lang" v-model="promotion.endDate" valueType="format" :first-day-of-week="1"></date-picker>
            </div>
          </div>

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Link:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="promotion.clickableLink" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <label class="control-label">Promotion image:</label> <br/>
              <input class="form-control" type="file"  ref="promotionImage" @change="handlePromotionImageUpload" />
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal id="edit-notice" title="Edit item on noticeboard" @cancel="true" @ok="validateBeforeSubmitEditNotice"   centered>
        <form action="#" class="form-horizontal"  method="post">
          <input type="hidden" name="_token" />
          <div class="form-body">

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Title:</label> <br/>
                  <div >
                    <input class="form-control" type="text"  v-model="noticeboard.title" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row" >
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Category:</label> <br/>
                  <div >
                    <select class="form-control" v-model="noticeboard.category">
                      <option v-for="category in noticeboard_categories"> {{ category }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <label class="control-label">Validity:</label>
                <date-picker  :lang="lang" v-model="noticeboard.startDate" valueType="format" :first-day-of-week="1"></date-picker> to
                <date-picker  :lang="lang" v-model="noticeboard.endDate" valueType="format" :first-day-of-week="1"></date-picker>
              </div>
            </div>

            <div class="row" v-if="noticeboard.imgUrl != null">
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="control-label ">Poster:</label> <br/>
                  <div >
                    <img :src="noticeboard.imgUrl" style="height: 50px;width: 50px;" />
                  </div>

                  <a @click="deletePoster" href="#" class="btn btn-danger">Delete</a>
                </div>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-md-12">
                <label class="control-label">Poster:</label> <br/>
                <input class="form-control" type="file"  ref="poster" @change="handlePosterUpload" />
              </div>
            </div>
          </div>
        </form>
    </b-modal>
    <b-modal id="add-category" title="Add Category" @cancel="true" @ok="validateBeforeSubmitNewCategory"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">


          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="category.name" />
                </div>
              </div>
            </div>
          </div>

        </div>

      </form>
    </b-modal>
    <b-modal id="add-tax" title="Add Tax" @cancel="true" @ok="saveTaxData"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">


          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="tax_data.taxName" />
                </div>
              </div>
            </div>
          </div>

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Value:</label> <br/>
                <div >
                  <input class="form-control" type="number"  v-model="tax_data.taxValue" />
                </div>
              </div>
            </div>
          </div>

        </div>

      </form>
    </b-modal>
    <b-modal id="edit-category" title="Edit Category" @cancel="true" @ok="validateBeforeSubmitEditCategory"  centered>
      <form action="#" class="form-horizontal"  method="post">

        <div class="form-body">

          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="category.name" />
                </div>
              </div>
            </div>
          </div>

        </div>

      </form>
    </b-modal>
    <b-modal  v-b-modal.modal-lg id="add-user" title="Add User" @cancel="true" @ok="validateBeforeSubmitNewUser"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.name" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Phone number:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.phoneNumber" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Email:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="user.username" />
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Role:</label> <br/>
                <div >
                  <select class="form-control" v-model="user.role">
                    <option :value="role" v-for="role in roles"> {{ role.role }}</option>
                  </select>
                </div>
              </div>


            </div>
          </div>

        </div>

      </form>
    </b-modal>
    <b-modal id="add-partner" title="Add Partner" @cancel="true" @ok="validateBeforeSubmitNewPartner"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="partner.name" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Email:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="partner.email" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Phone Number:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="partner.phoneNumber" />
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Partner Type:</label> <br/>
                <div >
                  <select class="form-control" v-model="partner.partnerType">
                    <option value=""> -- Select -- </option>
                    <option value="INTERNAL"> Internal </option>
                    <option value="EXTERNAL"> External </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal id="edit-partner" title="Edit Partner" @cancel="true" @ok="validateBeforeSubmitEditPartner"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Name:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="partner.name" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Email:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="partner.email" />
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Phone Number:</label> <br/>
                <div >
                  <input class="form-control" type="text"  v-model="partner.phoneNumber" />
                </div>
              </div>

              <div class="form-group ">
                <label class="control-label ">Partner Type:</label> <br/>
                <div >
                  <select class="form-control" v-model="partner.partnerType">
                    <option value=""> -- Select -- </option>
                    <option value="INTERNAL"> Internal </option>
                    <option value="EXTERNAL"> External </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal id="add-report-recipient" title="Add Report Recipients" @cancel="true" @ok="saveReportRecipientsSettingsData"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Report:</label> <br/>
                <div >
                  <select class="form-control" v-model="reportRecipientSetting.reportName">
                    <option> -- Select -- </option>
                    <option :value="reportName" v-for="reportName in reportNames">{{ reportName }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Recipients:</label> <br/>
                <div >
                  <textarea class="form-control"  v-model="reportRecipientSetting.recipients" rows="7">

                  </textarea>
                  <small class="form-text text-muted">Comma separated emails</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal id="edit-report-recipient" title="Edit Report Recipients" @cancel="true" @ok="editReportRecipientsSettingsData"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-12">
              <div class="form-group ">
                <label class="control-label ">Report:</label> <br/>
                <div >
                  <select class="form-control" v-model="reportRecipientSetting.reportName">
                    <option :value="reportName" v-for="reportName in reportNames">{{ reportName }}</option>
                  </select>
                </div>
              </div>
              <div class="form-group ">
                <label class="control-label ">Recipients:</label> <br/>
                <div >
                  <textarea class="form-control"  v-model="reportRecipientSetting.recipients" rows="7">

                  </textarea>
                  <small class="form-text text-muted">Comma separated emails</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>
<script>

  import draggable from "vuedraggable";

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'
  import { mapGetters, mapActions } from "vuex";

  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';

export default {
  display: "Transitions",
  order: 7,
  data() {
    return {
      promotions:[],
      noticeboard_categories:["CarsAndMotorbikes","PropertyForSaleOrRent","Event","EducationAndTraining","other"],
      partner_columns:[],
      partners:["name","email","phoneNumber","partnerType","options"],
      reportNames:["Customer vouchers report", "Customer gift redemption report",
        "Corporate voucher report", "Corporate voucher redemption report","Invoice accrual report",
        "Invoice redemption report", "Accrual report", "Redemption report", "Customers report",
        "Bonus points report", "Reversed transactions report", "Gift registry report"
      ],
      tierSettings:{
        reminderDays:"",
        reminderSMS:"",
        premiumMinimumPurchase:""
      },
      partner:{
        id:0,
        name:"",
        email:"",
        phoneNumber:"",
        partnerType:""
      },
      reportRecipientSetting:{
        id:0,
        reportName:"",
        recipients:""
      },
      headers_roles:[
        'role','Options'
      ],
      header_merchant_conversions:[{
        key:"outlet",
        label:"Outlet"
      },{
        key:"accrual",
        label:"Accrual"
      },
        {
          key:"redemption",
          label:"Redemption"
        },
        "options"
      ],
      noticeboard:{
          id:0,
          title:"",
          description:"",
          imgUrl:"",
          startDate:"",
          endDate:"",
          category:""
      },
      promotion:{
        id:0,
        name:"",
        description:"",
        promotionalUrl:"",
        startDate:"",
        endDate:"",
        outletId:"",
        clickableLink:""
      },
      lang: {
        days: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        pickers: ['next 7 days', 'next 30 days', 'previous 7 days', 'previous 30 days'],
        placeholder: {
          date: 'Select Date',
          dateRange: 'Select Date Range'
        }
      },
      general_settings:{
        openingTime:"",
        closingTime:"",
        customerServiceEmail:""
      },
      loyalty_settings:{
        loyalty_cash_value:0,
        max_loyalty_points_sa:0,
        max_loyalty_points_customer:0,
        netsuiteEnv:"",
        netsuiteItemId:""
      },
      lottery_settings:{
        num_of_tickets:"",
        conversion_rate:"",
        num_tickets_per_customer:"",
        num_of_transactions_per_day:"",
        maximum_amount_per_day:""
      },
      ranks: [],
      isLoading: false,
      fullPage: true,
      selected:[],
      category:{
        id:0,
        name:""
      },
      roles:[],

      user:{
        name:"",
        username:"",
        phoneNumber:"",
        role:"App Management"
      },
      columns: [
        "name",
        "options"
      ],
      columns_app_management: [
        "name",
        "username",
        "options"
      ],
      columns_taxes: [
        "taxName",
        "taxValue",
        "options"
      ],
      columns_noticeboard:[{
        key:"imgUrl",
        label:"Poster",
      }, "title", "category","startDate","endDate","options"],
      columns_promotions:[{
        key:"promotionalUrl",
        label:"Image",
      }, "name", "outlet","description","startDate","endDate","clickableLink","options"],
      columns_report_recipient_settings:[ "reportName", "recipients","options"],
      categories:[],
      users:[],
      notices:[],
      poster:"",
      outlets:[],
      smtp_settings:{
        provider:"",
        host:"",
        port:"",
        email:"",
        password:""
      },
      tax_data:{
        id:0,
        taxName:"",
        taxValue:""
      },
      loyaltyConverions:[],
      taxes:[],
      reportRecipientSettings:[]
    };
  },
  components: {
    Loading,
    DatePicker,
    draggable
  },
  computed: {
    ...mapGetters(["loading","error"]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },

  mounted (){
    this.getCategoryData();
    this.getUsersByRoleData(this.roles);
    this.getLoyaltySettingsData();
    this.getLotterySettingsData();
    this.getGeneralSettingsData();
    this.getNoticeData();
    this.getOutletData();
    this.getPromotionsData();
    this.getRolesData();
    this.getSMTPSettingsData();
    this.getMerchantLoyaltySettingsData();
    this.getTaxesData();
    this.getPartnersData();
    this.getTierSettingsData();
    this.getReportRecipientSettingsData();
  },

  methods:{
    ...mapActions(["triggerMonthlyReports","deleteReportRecipientSettings","getReportRecipientSettings","editReportRecipientsSettings","saveReportRecipientsSettings","getTierSettings","saveTierSettings","deleteTax","getMerchantLoyaltySettings","getRoles","getSMTPSettings","saveSMTPSettings","getOutlets","getPromotions","deletePromotion","savePromotion","addCategory","getCategories","editCategory","deleteCategory","getUsersByRole","addUser","deleteUser","updateLoyaltySettings","getLoyaltySettings","getLotterySettings","updateLotterySettings","getGeneralSettings","saveGeneralSettings","saveNotice","deleteNotice","getNotices","editNotice","saveTax","getTaxes","addPartner","editPartner","deletePartner","getPartners"]),
    getTierSettingsData() {
      let self = this;
      this.getTierSettings()
          .then((tierSettings) => {
            self.tierSettings = tierSettings;
          })
          .catch(error => {

          })
    },
    getReportRecipientSettingsData() {
      let self = this;
      this.getReportRecipientSettings()
          .then((reportRecipientSettings) => {
            self.reportRecipientSettings = reportRecipientSettings;
          })
          .catch(error => {

          })
    },
    saveReportRecipientsSettingsData(){
      this.isLoading = true;

      let self = this;

      this.saveReportRecipientsSettings( this.reportRecipientSetting)
          .then(() => {
            self.isLoading = false;

            self.$bvToast.toast("Report recipients settings saved.", {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: false
            });

            this.getReportRecipientSettingsData();

          })
          .catch(error => {
            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },
    editReportRecipientsSettingsData(){
      this.isLoading = true;

      let self = this;

      this.editReportRecipientsSettings( this.reportRecipientSetting)
          .then(() => {
            self.isLoading = false;

            self.$bvToast.toast("Report recipients settings saved.", {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: false
            });

            this.getReportRecipientSettingsData();

          })
          .catch(error => {
            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },
    deleteReportRecipientsSettingsData(){
      this.isLoading = true;

      let self = this;

      this.deleteReportRecipientSettings( this.reportRecipientSetting)
          .then(() => {
            self.isLoading = false;

            self.$bvToast.toast("Report recipients settings deleted.", {
              title: 'Success',
              variant: 'success',
              autoHideDelay: 5000,
              appendToast: false
            });

            this.getReportRecipientSettingsData();

          })
          .catch(error => {
            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },
    openDeletePartner(partner){
      this.partner = partner;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.partner.name +'?')
          .then(value => {

            if(value){
              self.confirmDeletePartner();
            }

          })
          .catch(err => {
            // An error occurred
          })
    },
    openDeleteReportRecipient(reportRecipientSetting){
      this.reportRecipientSetting = reportRecipientSetting;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.reportRecipientSetting.reportName +' with its recipients?')
          .then(value => {

            if(value){
              self.deleteReportRecipientsSettingsData();
            }

          })
          .catch(err => {
            // An error occurred
          })
    },
    confirmDeletePartner() {
      this.isLoading = true;

      let self = this;
      this.deletePartner({id: this.partner.id}).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Partner deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getPartnersData();

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },
    openEditPartnerModal(partner){
      this.partner = partner;
      this.$bvModal.show('edit-partner');
    },
    openEditReportRecipientSettingsModal(reportRecipientSetting){
      this.reportRecipientSetting = reportRecipientSetting;
      this.$bvModal.show('edit-report-recipient');
    },
    validateBeforeSubmitNewPartner() {
      this.isLoading = true;

      let self = this;

      this.addPartner(this.partner).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Partner added.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getPartnersData(self.roles);

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },
    validateBeforeSubmitEditPartner() {
      this.isLoading = true;

      let self = this;

      this.editPartner(this.partner).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Partner saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getPartnersData();

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })
    },
    saveTaxData(){
      this.isLoading = true;

      let self = this;

      this.saveTax( this.tax_data)
        .then(() => {
          self.isLoading = false;

          self.$bvToast.toast("Tax data saved.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          this.getTaxesData();

        })
        .catch(error => {


          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }

        })
    },
    getPartnersData(){
      let self = this;
      this.getPartners()
          .then((partners) => {
            self.partners = partners;
          })
          .catch(error => {

          })
    },
    getTaxesData(){
      let self = this;
      this.getTaxes()
        .then((roles) => {
          self.taxes = roles;
        })
        .catch(error => {

        })
    },
    navigateEditRole(role_id){
      this.$router.push("/apps/editRole/"+role_id);
    },
    navigateEditConversion(id){
      this.$router.push("/apps/editConversion/"+id);
    },
    getRolesData(){
      let self = this;
      this.getRoles()
        .then((roles) => {
          self.roles = roles;
        })
        .catch(error => {

        })
    },
    saveSMTPSettingsData(){

      this.isLoading = true;

      let self = this;

      this.saveSMTPSettings( this.smtp_settings)
        .then(() => {
          self.isLoading = false;

          self.$bvToast.toast("SMTP Settings updated.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          this.getSMTPSettings();

        })
        .catch(error => {


          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }

        })
    },
    getSMTPSettingsData(){


      this.getSMTPSettings()
        .then((smtp_settings) => {


          this.smtp_settings = smtp_settings;
        })
        .catch(error => {
          this.$vs.loading.close();
        })
    },
    getMerchantLoyaltySettingsData(){


      this.getMerchantLoyaltySettings()
        .then((loyaltyConverions) => {

          this.loyaltyConverions = loyaltyConverions;
        })
        .catch(error => {
          this.$vs.loading.close();
        })
    },
    getOutletData(){
      let self = this;
      this.getOutlets()
        .then(function (outlets) {

          self.outlets = outlets;


        })
        .catch(function (error) {
          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    deletePoster(){
      this.noticeboard.imgUrl = null;
    },
    handlePosterUpload(){
      this.poster = this.$refs.poster.files[0];
    },
    handlePromotionImageUpload(){
      this.promotionImage = this.$refs.promotionImage.files[0];
    },
    getLoyaltySettingsData(){
      let self = this;
      this.getLoyaltySettings()
        .then(function (loyalty_settings) {
          self.loyalty_settings = loyalty_settings;
        })
        .catch(function (error) {


          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    getLotterySettingsData(){
      let self = this;
      this.getLotterySettings()
        .then(function (lottery_settings) {

          self.lottery_settings = lottery_settings;
        })
        .catch(function (error) {


          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    getNoticeData(){
      let self = this;
      this.getNotices()
        .then(function (notices) {

          self.notices = notices;
        })
        .catch(function (error) {


          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    getPromotionsData(){
      let self = this;
      this.getPromotions()
        .then(function (promotions) {

          self.promotions = promotions;
        })
        .catch(function (error) {


          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    getGeneralSettingsData(){
      let self = this;
      this.getGeneralSettings()
        .then(function (general_settings) {


          self.general_settings = general_settings;
        })
        .catch(function (error) {


          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },
    validateBeforeSubmitNewPromotion(){
      this.isLoading = true;

      let self = this;

      let formdata = new FormData();

      formdata.append("name", this.promotion.name);
      formdata.append("description", this.promotion.description);
      formdata.append("startDate", this.promotion.startDate);
      formdata.append("endDate", this.promotion.endDate);
      formdata.append("promotionImage", this.promotionImage);
      formdata.append("outletId", this.promotion.outletId);
      formdata.append("clickableLink", this.promotion.clickableLink);


      this.savePromotion(formdata).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("New promotion added.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getPromotionsData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        });
    },
    validateBeforeSubmitNewNotice(){
      this.isLoading = true;

      let self = this;

      let formdata = new FormData();

      formdata.append("title", this.noticeboard.title);
      formdata.append("description", this.noticeboard.description);
      formdata.append("startDate", this.noticeboard.startDate);
      formdata.append("endDate", this.noticeboard.endDate);
      formdata.append("category", this.noticeboard.category);
      formdata.append("poster", this.poster);


      this.saveNotice(formdata).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("New item added to noticeboard.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getNoticeData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        });
    },
    validateBeforeSubmitEditNotice(){
      this.isLoading = true;

      let self = this;

      let formdata = new FormData();

      formdata.append("title", this.noticeboard.title);
      formdata.append("description", this.noticeboard.description);
      formdata.append("startDate", this.noticeboard.startDate);
      formdata.append("endDate", this.noticeboard.endDate);
      formdata.append("category", this.noticeboard.category);
      formdata.append("id", this.noticeboard.id);

      if(this.poster != ""){
        formdata.append("imgUrl",null);
        formdata.append("poster", this.poster);
      }
      else{
        formdata.append("imgUrl",this.noticeboard.imgUrl);
      }



      this.editNotice(formdata).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Noticeboard updated.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getNoticeData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        });
    },
    saveLotterySettings(){
      this.isLoading = true;

      this.lottery_settings.num_of_tickets = parseInt(this.lottery_settings.num_of_tickets);
      this.lottery_settings.conversion_rate = parseInt(this.lottery_settings.conversion_rate);
      this.lottery_settings.num_tickets_per_customer = parseInt(this.lottery_settings.num_tickets_per_customer);
      this.lottery_settings.num_of_transactions_per_day = parseInt(this.lottery_settings.num_of_transactions_per_day);
      this.lottery_settings.maximum_amount_per_day = parseInt(this.lottery_settings.maximum_amount_per_day);

      let self = this;

      this.updateLotterySettings(this.lottery_settings).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Lottery Settings updated.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getLotterySettingsData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })
    },
    saveTierSettingsData(){

      this.isLoading = true;

      let self = this;

      this.saveTierSettings(this.tierSettings).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Tier Settings updated.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getTierSettingsData();

      })
          .catch(error => {

            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });

          })

    },
    saveLoyaltySettings(){

      this.isLoading = true;

      this.loyalty_settings.loyalty_cash_value = parseFloat(this.loyalty_settings.loyalty_cash_value);
      this.loyalty_settings.max_pts_customer = parseInt(this.loyalty_settings.max_pts_customer);

      let self = this;

      this.updateLoyaltySettings(this.loyalty_settings).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Loyalty Settings updated.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getLoyaltySettingsData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },
    saveGeneralSettingsData(){

      this.isLoading = true;


      let self = this;

      this.saveGeneralSettings(this.general_settings).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("General settings updated.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getGeneralSettingsData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },
    openDeleteTax(tax_data){

      this.tax_data = tax_data;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.tax_data.taxName +' from system')
        .then(value => {

          if(value){
            self.confirmDeleteTax();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },
    openTriggerMonthlyReports(tax_data){

      this.tax_data = tax_data;

      let self = this;
      this.$bvModal.msgBoxConfirm('Send listed reports to recipients ?')
          .then(value => {

            if(value){
              self.confirmTriggerMonthlyReports();
            }

          })
          .catch(err => {
            // An error occurred
          })
    },

    openDeleteUser(user){

      this.user = user;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.user.name +' from system')
        .then(value => {

          if(value){
            self.confirmDeleteUser();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },
    openDeleteNotice(noticeBoard){

      this.noticeboard = noticeBoard;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.noticeboard.title +' from system')
        .then(value => {

          if(value){
            self.confirmDeleteNoticeBoard();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },
    openDeletePromotion(promotion){

      this.promotion = promotion;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.promotion.name +' from system')
        .then(value => {

          if(value){
            self.confirmDeletePromotion();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },
    confirmDeleteUser(){
      this.isLoading = true;

      let self = this;

      this.deleteUser({user_id: this.user.id}).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("User deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getUsersData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },

    confirmTriggerMonthlyReports(){
      this.isLoading = true;

      let self = this;

      this.triggerMonthlyReports().then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Monthly reports triggered", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

      })
          .catch(error => {
            self.isLoading = false;

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });
          })
    },

    confirmDeleteTax(){
      this.isLoading = true;

      let self = this;

      this.deleteTax({tax_id: this.tax_data.id}).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Tax deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getTaxesData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })
    },
    confirmDeleteNoticeBoard(){
      this.isLoading = true;

      let self = this;

      this.deleteNotice({noticeBoardId: this.noticeboard.id}).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Noticeboard item deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getNoticeData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },
    confirmDeletePromotion(){
      this.isLoading = true;

      let self = this;

      this.deletePromotion({promotionId: this.promotion.id}).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Promotion deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getPromotionsData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },
    getUsersByRoleData(role_arr){

      let self = this;

      this.getUsersByRole({roles: role_arr})
        .then(function (users) {
          self.users = users;
        })
        .catch(function (error) {

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        });

    },

    getCategoryData(){

      let self = this;
      this.getCategories()
        .then(function (categories) {

          self.categories = categories;
        })
        .catch(function (error) {


          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },

    confirmDelete(){
      this.isLoading = true;

      let self = this;

      this.deleteCategory(this.category).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("County deleted.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getCategoryData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },

    validateBeforeSubmitNewCategory(){

      this.isLoading = true;

      let self = this;

        this.addCategory(this.category).then(() => {
          self.isLoading = false;

          self.$bvToast.toast("Category added.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getCategoryData();

        })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

          /*if( typeof error.response.status != "undefined"){
             if(error.response.status == 401){
               location.replace("/app/sessions/signIn");
             }

          }
          else{




          }*/
        })
    },

    validateBeforeSubmitEditCategory(){
      this.isLoading = true;

      let self = this;

      this.editCategory(this.category).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("Category saved.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getCategoryData();

      })
        .catch(error => {

          self.isLoading = false;

          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
          else{

            self.$bvToast.toast(error.message, {
              title: 'Error',
              variant: 'danger',
              autoHideDelay: 5000,
              appendToast: false
            });


          }
        })

    },


    openEditCategoryModal(category){

      this.category = category;
      this.$bvModal.show('edit-category');

    },
    openEditNoticeBoardModal(noticeBoard){

      this.noticeboard = noticeBoard;
      this.$bvModal.show('edit-notice');

    },

    openDeleteCategoryModal(category){

      this.category = category;

      let self = this;
      this.$bvModal.msgBoxConfirm('Delete '+this.category.name +' from system')
        .then(value => {
          if(value){
            self.confirmDelete();
          }

        })
        .catch(err => {
          // An error occurred
        })
    },

    validateBeforeSubmitNewUser(){
      this.isLoading = true;

      let self = this;

      this.addUser(this.user).then(() => {
        self.isLoading = false;

        self.$bvToast.toast("User added.", {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 5000,
          appendToast: false
        });

        self.getUsersByRoleData(self.roles);

      })
        .catch(error => {

          self.isLoading = false;

          self.$bvToast.toast(error.message, {
            title: 'Error',
            variant: 'danger',
            autoHideDelay: 5000,
            appendToast: false
          });

          /*if( typeof error.response.status != "undefined"){
             if(error.response.status == 401){
               location.replace("/app/sessions/signIn");
             }

          }
          else{




          }*/
        })
    }
  }
};
</script>
